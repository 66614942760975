import server from "../axios.config.js";

// 查询种植档案
export const plantFileById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantFile/getById?id=${id}`,
        method: "GET"
    })
}

// 查询地块坐标
export const LandById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/land/getById?id=${id}`
    })
}

// 查询所有的树
export const selectPageListAll = function(plantFileId, current, size, code, status) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantTree/selectPageListAll?current=${current}&plantFileId=${plantFileId}&size=${size}&code=${code}&status=${status}`,
        method: "GET"
    })
}

// 根据id查询种植档案详情
export const plantTreeGetById = function(id) {
    return server({
        url: `http://110.87.103.59:18086/farmfriend/plantTree/getById?id=${id}`
    })
}